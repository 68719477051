import * as React from "react";
import Layout from "../components/layout.js";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { useState } from "react";

const MainImage = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: opacity 250ms ease-in-out;
`;

const AboutText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  width: 70%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
`;

const EmailContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  pointer-events: auto;
`;

const Email = styled.a`
  display: block;
  padding: 0.5rem;
  font-size: 1.5rem;
`;

// markup
const IndexPage = () => {
  const [page, setPage] = useState(null);

  return (
    <Layout page={page} setPage={setPage}>
      <MainImage>
        <PageContainer active={page === null}>
          <StaticImage
            style={{
              height: "100%",
              width: "100%",
              zIndex: -2,
              pointerEvents: "none",
            }}
            src="../images/iStock-148228557.jpg"
            alt="Fries"
            placeholder="dominantColor"
            layout="fullWidth"
            objectFit={"contain"}
          />
        </PageContainer>
        <PageContainer active={page === "ABOUT"}>
          <StaticImage
            style={{
              height: "100%",
              width: "100%",
              zIndex: -1,
              pointerEvents: "none",
            }}
            src="../images/iStock-518801978.jpg"
            alt="Fries"
            placeholder="dominantColor"
            layout="fullWidth"
            objectFit={"contain"}
          />
          <AboutText>A bunch of weird, deep-fried, creative potato</AboutText>
        </PageContainer>
        <PageContainer active={page === "CONTACT"}>
          {/*<StaticImage*/}
          {/*    style={{ height: "100%", width: "100%", zIndex: -1 }}*/}
          {/*    src="../images/iStock-518801978.jpg"*/}
          {/*    alt="Fries"*/}
          {/*    placeholder="dominantColor"*/}
          {/*    layout="fullWidth"*/}
          {/*    objectFit={"contain"}*/}
          {/*/>*/}
          <EmailContainer>
            <Email href={"mailto:michael@suebo.xyz"}>Michael@suebo.xyz</Email>
            <Email href={"mailto:kendy@suebo.xyz"}>Kendy@suebo.xyz </Email>
          </EmailContainer>
        </PageContainer>
      </MainImage>
    </Layout>
  );
};

export default IndexPage;
