import React from "react";
import "normalize.css";
import "./global.css";
import styled from "styled-components";

const LogoLink = styled.a``;

const Logo = styled.h1`
  margin: 0;
  cursor: pointer;
`;

const Header = styled.header`
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Main = styled.main``;

const Nav = styled.nav`
  display: flex;
  margin-right: -1rem;
`;

const NavLink = styled.a`
  //margin-left: 1rem;
  display: block;
  padding: 1rem;
  cursor: pointer;
`;

// const Footer = styled.footer`
//   position: absolute;
//   bottom: 0;
//   left: 50%;
//   transform: translateX(-50%);
//   font-size: 0.8rem;
// `;

const Layout = ({ children, page, setPage }) => {
  return (
    <>
      <Header>
        <LogoLink
          onClick={() => {
            setPage(null);
          }}
        >
          <Logo>Suebo</Logo>
        </LogoLink>
        <Nav>
          <NavLink
            onClick={() => {
              if (page === "ABOUT") {
                setPage(null);
              } else {
                setPage("ABOUT");
              }
            }}
          >
            about
          </NavLink>
          <NavLink
            // href={"mailto:michael@suebo.xyz"}
            onClick={() => {
              if (page === "CONTACT") {
                setPage(null);
              } else {
                setPage("CONTACT");
              }
            }}
          >
            contact
          </NavLink>
        </Nav>
      </Header>
      <Main>{children}</Main>
      {/*<Footer>Copyright Suebo</Footer>*/}
    </>
  );
};

export default Layout;
